import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Container, Row, Col, Alert, Card } from "react-bootstrap";
import Avatar from "react-avatar";
import { obtenerPerfil, actualizarPerfil } from "../redux/profileSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { usuario, loading, error, mensaje } = useSelector((state) => state.profile);

  const [formData, setFormData] = useState({
    nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    telefono: "",
    direccion: "",
    email_personal: "",
    contacto_emergencia: "",
    telefono_emergencia: "",
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(obtenerPerfil());
  }, [dispatch]);

  useEffect(() => {
    if (usuario) {
      setFormData({
        nombre: usuario.nombre || "",
        primer_apellido: usuario.primer_apellido || "",
        segundo_apellido: usuario.segundo_apellido || "",
        telefono: usuario.telefono || "",
        direccion: usuario.direccion || "",
        email_personal: usuario.email_personal || "",
        contacto_emergencia: usuario.contacto_emergencia || "",
        telefono_emergencia: usuario.telefono_emergencia || "",
      });
    }
  }, [usuario]);

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "nombre":
      case "primer_apellido":
      case "segundo_apellido":
        if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+$/.test(value)) {
          error = "Este campo solo puede contener letras.";
        }
        break;
      case "telefono":
      case "telefono_emergencia":
        if (!/^\+56\d{9}$/.test(value)) {
          error = "Debe ser un número válido para Chile (+56XXXXXXXXX).";
        }
        break;
      case "email_personal":
        if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
          error = "Debe ser un email válido.";
        }
        break;
      case "direccion":
      case "contacto_emergencia":
        if (value.trim() === "") {
          error = "Este campo no puede estar vacío.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setFormErrors({ ...formErrors, [name]: error });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) errors[key] = error;
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setFormErrors({});
    dispatch(actualizarPerfil(formData));
  };

  if (loading && !usuario) {
    return <Container><p>Cargando perfil...</p></Container>;
  }

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row className="mb-4 text-center">
                <Col>
                  <Avatar name={`${formData.nombre} ${formData.primer_apellido}`} size="100" round={true} />
                  <h3>{`${formData.nombre} ${formData.primer_apellido} ${formData.segundo_apellido}`}</h3>
                  <p>{formData.direccion}</p>
                </Col>
              </Row>
              {error && <Alert variant="danger">{error}</Alert>}
              {mensaje && <Alert variant="success">{mensaje}</Alert>}
              <Form onSubmit={handleFormSubmit}>
                {Object.keys(formData).map((key) => (
                  <Form.Group controlId={`form${key}`} className="mb-3" key={key}>
                    <Form.Label>{key.replace("_", " ").toUpperCase()}</Form.Label>
                    <Form.Control
                      type="text"
                      name={key}
                      value={formData[key]}
                      onChange={handleInputChange}
                      placeholder={`Ingresa ${key.replace("_", " ")}`}
                      isInvalid={!!formErrors[key]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors[key]}
                    </Form.Control.Feedback>
                  </Form.Group>
                ))}
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Guardando..." : "Actualizar Perfil"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage; 