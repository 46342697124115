import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { marcarNotificacionLeida } from '../../redux/notificacionesSlice';

const NotificacionesMenu = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const notificaciones = useSelector((state) => state.notificaciones.lista);

  if (!isOpen) return null; // No renderizar si el menú está cerrado

  return (
    <div className="dropdown-menu dropdown-menu-end show p-2 shadow" style={{ minWidth: '250px', position: 'absolute', right: 0 }}>
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="dropdown-header mb-0">Notificaciones</h6>
        <button className="btn btn-sm btn-outline-danger" onClick={onClose}>❌</button>
      </div>
      <hr className="my-1" />
      {notificaciones.length === 0 ? (
        <p className="dropdown-item text-center text-muted">No hay notificaciones</p>
      ) : (
        notificaciones.map((notif) => (
          <button
            key={notif.id}
            className="dropdown-item text-wrap"
            onClick={() => dispatch(marcarNotificacionLeida(notif.id))}
          >
            {notif.mensaje}
          </button>
        ))
      )}
    </div>
  );
};

export default NotificacionesMenu;
