import React, { useState } from "react";
import { Offcanvas, Button, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const AdminPanel = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const linkStyle = {
    color: "#d0021b", // Color rojo del logo
    textDecoration: "none",
    fontWeight: "bold",
  };

  const hoverStyle = {
    color: "#a00115", // Color más oscuro para hover
  };

  return (
    <>
      {/* Botón del Panel de Administración */}
      <Button
        onClick={handleShow}
        style={{
          backgroundColor: "#d0021b", // Color rojo del logo
          border: "none",
          fontWeight: "bold",
          textTransform: "uppercase",
          padding: "8px 16px",

        }}
      >
        Panel de Administración
      </Button>

      {/* Offcanvas para el Panel */}
      <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: "300px" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            style={{
              color: "#d0021b", // Color rojo del logo
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "1.5rem",
            }}
          >
            Panel de Administración
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup variant="flush">
            {/* Nuevo elemento: Gestión de Usuarios */}
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/usuarios"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Gestión de Usuarios
              </Link>
            </ListGroup.Item>

            {/* Nuevo elemento: Gestión de Usuarios */}
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/convenios"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Gestión de Convenios
              </Link>
            </ListGroup.Item>

            {/* Elementos existentes */}
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/noticias"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Gestión de Noticias
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/documentos"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Gestión de Documentos
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/beneficios"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Gestión de Beneficios
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/encuestas"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Gestión de Encuestas
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/correos"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Correo Masivo
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/dashboard"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Dashboard General
              </Link>
            </ListGroup.Item>

            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/carga-masiva-usuarios"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Carga Masiva de Usuarios
              </Link>
            </ListGroup.Item>

            <ListGroup.Item className="px-3 py-2">
              <Link
                to="/admin/exportar-usuarios"
                style={linkStyle}
                onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
              >
                Exportar Usuarios
              </Link>
            </ListGroup.Item>

          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AdminPanel;
