import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  enviarCorreoMasivo,
  obtenerHistorialCorreos,
  eliminarCorreo,
  
} from "../../redux/correosSlice";
import { Button, Modal, Form, Table, Spinner, Alert, ProgressBar } from "react-bootstrap";
import api from "../../utils/axiosConfig";

const CorreoMasivoPage = () => {
  const dispatch = useDispatch();
  const { correos, loading, error, successMessage } = useSelector((state) => state.correo);

  const [showModal, setShowModal] = useState(false);
  const [correoDetails, setCorreoDetails] = useState({
    asunto: "",
    mensaje: "",
    cc: "",
    bcc: "",
    adjuntos: [],
  });
  const [progress, setProgress] = useState(0);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    dispatch(obtenerHistorialCorreos());
  }, [dispatch]);

  const handleCloseModal = () => {
    setShowModal(false);
    setCorreoDetails({ asunto: "", mensaje: "", cc: "", bcc: "", adjuntos: [] });
  };

  const handleInputChange = (e) => {
    setCorreoDetails({ ...correoDetails, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e) => {
    setCorreoDetails({ ...correoDetails, adjuntos: [...e.target.files] });
  };

  const handleSendCorreo = async () => {
    if (!correoDetails.asunto || !correoDetails.mensaje || correoDetails.mensaje.length <= 10) {
      alert("Completa los campos correctamente.");
      return;
    }

    try {
      setSending(true);
      setProgress(0);
      await dispatch(enviarCorreoMasivo(correoDetails)).unwrap();

      const interval = setInterval(async () => {
        try {
          const response = await api.get("/api/correo/progreso");
          const { total, completed } = response.data;

          if (total === 0) {
            clearInterval(interval);
            setSending(false);
            return;
          }

          const progressPercentage = Math.round((completed / total) * 100);
          setProgress(progressPercentage);

          if (completed >= total) {
            clearInterval(interval);
            setSending(false);
            dispatch(obtenerHistorialCorreos());
          }
        } catch (err) {
          clearInterval(interval);
          setSending(false);
        }
      }, 1000);
    } catch (err) {
      setSending(false);
    }
  };

  return (
    <div>
      <h1>Gestión de Correos Masivos</h1>

      <Button onClick={() => setShowModal(true)} className="mb-3">
        Enviar Correo Masivo
      </Button>

      {loading && <Spinner animation="border" />}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}

      {sending && <ProgressBar animated now={progress} label={`${progress}%`} className="mb-3" />}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Asunto</th>
            <th>Mensaje</th>
            <th>Estado</th>
            <th>Fecha de Envío</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {correos.map((correo, index) => (
            <tr key={correo.id}>
              <td>{index + 1}</td>
              <td>{correo.asunto}</td>
              <td>{correo.mensaje}</td>
              <td>{correo.estado}</td>
              <td>{new Date(correo.fecha_envio).toLocaleString("es-CL")}</td>
              <td>
                <Button variant="danger" onClick={() => dispatch(eliminarCorreo(correo.id))}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar Correo Masivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Asunto</Form.Label>
              <Form.Control type="text" name="asunto" onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" rows={5} name="mensaje" onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>CC</Form.Label>
              <Form.Control type="text" name="cc" onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>BCC</Form.Label>
              <Form.Control type="text" name="bcc" onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Adjuntar Archivos</Form.Label>
              <Form.Control type="file" multiple onChange={handleFileUpload} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
          <Button variant="primary" onClick={handleSendCorreo}>Enviar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CorreoMasivoPage;
