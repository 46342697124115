import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchNotificaciones = createAsyncThunk(
  'notificaciones/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/notificaciones', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      if (!response.ok) throw new Error('Error al obtener notificaciones');
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const marcarNotificacionLeida = createAsyncThunk(
  'notificaciones/marcarLeida',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/notificaciones/${id}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      if (!response.ok) throw new Error('Error al marcar como leída');
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const notificacionesSlice = createSlice({
  name: 'notificaciones',
  initialState: { lista: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificaciones.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Error desconocido al obtener notificaciones';
      })
      .addCase(fetchNotificaciones.fulfilled, (state, action) => {
        state.lista = action.payload;
        state.status = 'succeeded';
      })
    .addCase(marcarNotificacionLeida.fulfilled, (state, action) => {
      state.lista = state.lista.filter(notif => notif.id !== action.payload);
    });
},
});

export default notificacionesSlice.reducer;
