import React, { useState } from "react";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import api from "../../utils/axiosConfig"; // Usamos Axios con la configuración global
import { startExport, exportSuccess, exportFailure } from "../../redux/exportSlice";

const ExportarUsuariosPage = () => {
    const [format, setFormat] = useState("pdf"); // Estado para el formato seleccionado
    const { loading, error } = useSelector((state) => state.export);
    const [successMessage, setSuccessMessage] = useState(null);
    const dispatch = useDispatch();

    const handleExport = async () => {
        try {
            dispatch(startExport());
            setSuccessMessage(null); // Limpiar mensaje de éxito previo
            console.log(`Iniciando exportación en formato: ${format}`);

            const response = await api.get(`/api/export/${format}`, { responseType: "blob" });
            console.log("Respuesta recibida:", response);

            if (!response.data || response.data.size < 5) {
                throw new Error("El archivo exportado está vacío o no se generó correctamente.");
            }

            // Crear un enlace de descarga con el nombre correcto
            console.log("Generando enlace de descarga...");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;

            // **Asegurar la extensión correcta**
            const extension = format === "excel" ? "xlsx" : format === "pdf" ? "pdf" : format === "txt" ? "txt" : "docx";
            a.download = `usuarios.${extension}`;

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            dispatch(exportSuccess());
            setSuccessMessage(`La exportación en formato ${extension.toUpperCase()} se completó con éxito.`);
            console.log("Exportación completada.");
        } catch (error) {
            console.error("Error al exportar:", error);
            dispatch(exportFailure(error.message || "Error inesperado en la exportación."));
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-3">Exportar Usuarios</h2>

            {/* Mensaje informativo */}
            <Alert variant="info">
                <p>Esta función te permite exportar la lista de usuarios en diferentes formatos: <strong>PDF, TXT, Excel y Word</strong>.</p>
                <p>Los datos exportados incluyen:</p>
                <ul>
                    <li>Nombre completo</li>
                    <li>Teléfono (si está registrado)</li>
                    <li>Email institucional y personal</li>
                    <li>Contacto de emergencia y dirección</li>
                </ul>
                <p>Selecciona un formato y haz clic en <strong>"Exportar Usuarios"</strong> para descargar el archivo.</p>
            </Alert>

            {/* Mostrar mensaje de éxito si la exportación fue exitosa */}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            {/* Mostrar error si existe */}
            {error && <Alert variant="danger">{error}</Alert>}

            <Form>
                <Form.Group controlId="exportFormat">
                    <Form.Label><strong>Selecciona el formato de exportación:</strong></Form.Label>
                    <Form.Control as="select" value={format} onChange={(e) => setFormat(e.target.value)}>
                        <option value="pdf">📄 PDF</option>
                        <option value="txt">📜 TXT</option>
                        <option value="excel">📊 Excel</option>
                        <option value="word">📝 Word</option>
                    </Form.Control>
                </Form.Group>

                <Button 
                    variant="danger" 
                    className="mt-3" 
                    onClick={handleExport} 
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <Spinner animation="border" size="sm" /> Exportando...
                        </>
                    ) : "Exportar Usuarios"}
                </Button>
            </Form>
        </div>
    );
};

export default ExportarUsuariosPage;
