import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, requiredRole }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const cambioClave = useSelector((state) => state.auth.cambioClave);
  const completarEmergencia = useSelector((state) => state.auth.completarEmergencia);
  const rol = useSelector((state) => state.auth.usuario.rol);
  const location = useLocation();

  console.log("ProtectedRoute Check:", { isAuthenticated, cambioClave, completarEmergencia, rol });

  // 🔴 Si el usuario necesita cambiar clave o completar datos, lo forzamos a quedarse en "/cambio-clave"
  if ((cambioClave || completarEmergencia) && location.pathname !== "/cambio-clave") {
    return <Navigate to="/cambio-clave" replace />;
  }

  // Si el usuario no está autenticado, redirigir a "/login"
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Si la ruta requiere un rol específico y el usuario no lo tiene, redirigir a "/"
  if (requiredRole && rol !== requiredRole) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
