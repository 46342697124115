import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegiones, fetchComunasByRegion } from "../../redux/regionComunaSlice";
import { Button, Modal, Form, Tab, Tabs } from "react-bootstrap";
import RegisterUser from "../../components/Auth/registerUser";
import ListarUsuarios from "../../components/Auth/ListarUsuarios";
import axios from "../../utils/axiosConfig";
import { Toast, ToastContainer } from "react-bootstrap";



const UsuariosPage = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    region_id: "",
    comuna_id: "",
  });
  
  const [key, setKey] = useState("listar");
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const dispatch = useDispatch();
  const { regiones, comunas } = useSelector((state) => state.regionComuna);
  const [showToast, setShowToast] = useState(false);


  // Cargar regiones al montar el componente
  useEffect(() => {
    dispatch(fetchRegiones());
  }, [dispatch]);

  // Cargar comunas cuando se seleccione una región
  useEffect(() => {
    if (currentUser?.region_id) {
      dispatch(fetchComunasByRegion(currentUser.region_id)).unwrap().then((comunasData) => {
        setCurrentUser((prevUser) => ({
          ...prevUser,
          comuna_id: "", // Reinicia comuna
        }));
      });
    }
  }, [currentUser?.region_id, dispatch]);



  // Obtener la lista de usuarios
  const fetchUsuarios = async () => {
    try {
      const response = await axios.get("/auth/admin/listar-usuarios", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setUsuarios(response.data);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  };

  // Eliminar Usuario
  const eliminarUsuario = async (id) => {
    try {
      await axios.delete(`/auth/admin/eliminar-usuario/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      fetchUsuarios(); // Actualizar la lista después de eliminar
    } catch (error) {
      console.error("Error al eliminar usuario:", error);
    }
  };

  // Abrir Modal para Editar Rol
  const openEditModal = (user) => {
    setCurrentUser(user);
    setShowEditModal(true);
  };

  // Manejar cambio de Rol en el Modal
  const handleRoleChange = (e) => {
    setCurrentUser({ ...currentUser, rol: e.target.value });
  };

  // Actualizar Rol del Usuario
  const actualizarRol = async () => {
    try {
      await axios.put(
        `/auth/admin/actualizar-rol-usuario/${currentUser.id}`,
        { rol: currentUser.rol },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setShowEditModal(false); // Cerrar el modal después de actualizar
      fetchUsuarios(); // Actualizar la lista de usuarios
    } catch (error) {
      console.error("Error al actualizar rol:", error);
    }
  };

  /* Abrir Modal para Modificar Usuario !!
   CORREGIR CONTROLADOR: /auth/usuario/${user.id}` DECRYPT FUNC !! */
  const openEditUserModal = async (user) => {
    try {
      const response = await axios.get(`/auth/usuario/${user.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const userData = response.data;

      setCurrentUser({
        ...userData,
        region_id: userData.region_id || "",
        comuna_id: userData.comuna_id || "",
      });

      // Cargar comunas según la región del usuario
      if (userData.region_id) {
        dispatch(fetchComunasByRegion(userData.region_id));
      }

      setShowEditUserModal(true);
    } catch (error) {
      console.error("Error al obtener datos del usuario:", error);
    }
  };






  // Actualizar Usuario
  const actualizarUsuario = async () => {
    try {
      const response = await axios.put(
        `/auth/actualizar-usuario/${currentUser.id}`,
        {
          nombre: currentUser.nombre,
          primer_apellido: currentUser.primer_apellido,
          segundo_apellido: currentUser.segundo_apellido,
          telefono: currentUser.telefono,
          direccion: currentUser.direccion,
          email_personal: currentUser.email_personal,
          contacto_emergencia: currentUser.contacto_emergencia,
          telefono_emergencia: currentUser.telefono_emergencia,
          region_id: parseInt(currentUser.region_id, 10),
          comuna_id: parseInt(currentUser.comuna_id, 10),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      console.log("Respuesta del servidor:", response.data);
      setShowToast(true); // Mostrar Toast
      setShowEditUserModal(false); // Cerrar el modal
      fetchUsuarios(); // Actualizar lista de usuarios
    } catch (error) {
      console.error("Error al actualizar usuario:", error.response?.data || error.message);
    }
  };



  useEffect(() => {
    fetchUsuarios(); // Obtener usuarios al cargar la página
  }, []);

  return (
    <div>
      <h1>Gestión de Usuarios</h1>


      <ToastContainer className="toast-center">
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={5000} autohide bg="success">
          <Toast.Header>
            <strong className="me-auto">Éxito</strong>
          </Toast.Header>
          <Toast.Body>Usuario actualizado con éxito</Toast.Body>
        </Toast>
      </ToastContainer>



      <Tabs
        id="usuarios-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="registrar" title="Registrar Usuario">
          <RegisterUser />
        </Tab>
        <Tab eventKey="listar" title="Lista de Usuarios">
          <ListarUsuarios
            usuarios={usuarios}
            eliminarUsuario={eliminarUsuario}
            openEditModal={openEditModal}
            openEditUserModal={openEditUserModal}
            fetchUsuarios={fetchUsuarios}
          />
        </Tab>
      </Tabs>

      {/* Modal para Editar Rol */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Rol de Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formRole">
              <Form.Label>Rol</Form.Label>
              <Form.Control
                as="select"
                value={currentUser?.rol || ""}
                onChange={handleRoleChange}
              >
                <option value="socio">Socio</option>
                <option value="administrador">Administrador</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={actualizarRol}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Modificar Usuario */}
      <Modal show={showEditUserModal} onHide={() => setShowEditUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modificar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={currentUser?.nombre || ""}
                onChange={(e) =>
                  setCurrentUser({ ...currentUser, nombre: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPrimerApellido">
              <Form.Label>Primer Apellido</Form.Label>
              <Form.Control
                type="text"
                value={currentUser?.primer_apellido || ""}
                onChange={(e) =>
                  setCurrentUser({
                    ...currentUser,
                    primer_apellido: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formSegundoApellido">
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                value={currentUser?.segundo_apellido || ""}
                onChange={(e) =>
                  setCurrentUser({
                    ...currentUser,
                    segundo_apellido: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formTelefono">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                value={currentUser?.telefono || ""}
                onChange={(e) =>
                  setCurrentUser({ ...currentUser, telefono: e.target.value })
                }
              />

            </Form.Group>
            <Form.Group controlId="formRegion">
              <Form.Label>Región</Form.Label>
              <Form.Select
                value={currentUser?.region_id || ""}
                onChange={(e) => {
                  const regionId = parseInt(e.target.value, 10) || "";

                  setCurrentUser({
                    ...currentUser,
                    region_id: regionId, // Se actualiza la región
                    comuna_id: "", // 🔥 Reinicia la comuna para que no muestre la anterior
                    comuna_nombre: "", // 🔥 También limpia el nombre de la comuna en la UI
                  });

                  dispatch(fetchComunasByRegion(regionId)); // Cargar comunas de la nueva región
                }}
              >
                <option value="">Seleccione una región</option>
                {regiones.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>




            <Form.Group controlId="formComuna">
              <Form.Label>Comuna</Form.Label>
              <Form.Select
                key={currentUser?.region_id} // 🔥 Forzar re-render cuando cambia la región
                value={currentUser?.comuna_id || ""}
                onChange={(e) => {
                  const comunaSeleccionada = comunas.find(c => c.id === parseInt(e.target.value, 10));
                  setCurrentUser({
                    ...currentUser,
                    comuna_id: e.target.value, // Actualizar comuna en el estado
                    comuna_nombre: comunaSeleccionada ? comunaSeleccionada.nombre : "", // Asegurar que el nombre se actualice
                  });
                }}
                disabled={!currentUser?.region_id || comunas.length === 0}
              >
                <option value="">Seleccione una comuna</option>
                {comunas
                  .filter((comuna) => comuna.region_id === parseInt(currentUser.region_id, 10)) // 🔥 Mostrar solo comunas de la región seleccionada
                  .map((comuna) => (
                    <option key={comuna.id} value={comuna.id}>
                      {comuna.nombre}
                    </option>
                  ))}
              </Form.Select>

            </Form.Group>



            <Form.Group controlId="formDireccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                value={currentUser?.direccion || ""}
                onChange={(e) =>
                  setCurrentUser({ ...currentUser, direccion: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmailPersonal">
              <Form.Label>Email Personal</Form.Label>
              <Form.Control
                type="email"
                value={currentUser?.email_personal || ""}
                onChange={(e) =>
                  setCurrentUser({
                    ...currentUser,
                    email_personal: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formContactoEmergencia">
              <Form.Label>Contacto de Emergencia</Form.Label>
              <Form.Control
                type="text"
                value={currentUser?.contacto_emergencia || ""}
                onChange={(e) =>
                  setCurrentUser({
                    ...currentUser,
                    contacto_emergencia: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formTelefonoEmergencia">
              <Form.Label>Teléfono de Emergencia</Form.Label>
              <Form.Control
                type="text"
                value={currentUser?.telefono_emergencia || ""}
                onChange={(e) =>
                  setCurrentUser({
                    ...currentUser,
                    telefono_emergencia: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditUserModal(false)}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={actualizarUsuario}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsuariosPage;
