  import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
  import api from "../utils/axiosConfig";

  // Acción asincrónica para subir el archivo Excel
  // Acción asincrónica para subir el archivo Excel
export const uploadFile = createAsyncThunk(
  "cargaMasiva/uploadFile",
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("archivoExcel", file);

      console.log("Enviando archivo al backend:", file.name);

      const response = await api.post("/api/carga-masiva", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Axios maneja el boundary automáticamente
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error en la carga:", error.response?.data);
      return rejectWithValue(error.response?.data || { message: "Error al cargar el archivo." });
    }
  }
);





  const cargaMasivaSlice = createSlice({
    name: "cargaMasiva",
    initialState: {
      loading: false,
      success: null,
      error: null,
      resultados: [],
    },
    reducers: {
      clearMessages: (state) => {
        state.success = null;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadFile.pending, (state) => {
          state.loading = true;
          state.success = null;
          state.error = null;
        })
        .addCase(uploadFile.fulfilled, (state, action) => {
          state.loading = false;
          state.success = "Carga masiva completada.";
          state.resultados = action.payload;
        })
        .addCase(uploadFile.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || "Error al procesar el archivo.";
        });
    },
  });

  export const { clearMessages } = cargaMasivaSlice.actions;
  export default cargaMasivaSlice.reducer;
