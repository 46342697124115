import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';
import { setError } from './errorSlice';

// Solicitar recuperación de contraseña
export const requestPasswordRecovery = createAsyncThunk(
  'auth/requestPasswordRecovery',
  async (email, { rejectWithValue }) => {
    try {
      // Ajuste: Asegurarse de que email sea un string válido antes de enviarlo
      if (typeof email !== 'string' || email.trim() === '') {
        throw new Error('El correo electrónico es inválido.');
      }

      const response = await api.post('/auth/recover-password', { email: email.trim() });
      console.log("Correo electrónico enviado para recuperación:", email.trim());
      return response.data;
    } catch (error) {
      console.error('Error desde el backend:', error.response?.data || error.message);
      // Extrae errores desde el backend o usa un mensaje por defecto
      return rejectWithValue(error.response?.data?.errors || 'Error al solicitar recuperación de contraseña');
    }
  }
);


// Restablecer la contraseña
export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post('/auth/reset-password', data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.error || 'Error al restablecer la contraseña');
  }
});


// Acción para logout asíncrono
export const logoutAsync = createAsyncThunk('auth/logoutAsync', async (_, { rejectWithValue }) => {
  try {
    await api.get('/auth/logout');
    return true;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error al cerrar sesión');
  }
});

// Obtener perfil del usuario
export const fetchProfile = createAsyncThunk('auth/fetchProfile', async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/auth/profile');
    console.log("Perfil recibido desde el backend:", response.data);

    const { usuario, cambio_clave, completar_emergencia } = response.data;

    if (completar_emergencia) {
      dispatch(setError({
        message: 'Debes actualizar tu contacto de emergencia y/o teléfono de emergencia.',
        status: 400,
        redirectTo: '/cambio-clave',
      }));
    }

    return { ...usuario, cambio_clave, completar_emergencia };
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error al obtener el perfil');
  }
});


// Actualizar perfil del usuario
export const updateProfile = createAsyncThunk('auth/updateProfile', async (profileData, { rejectWithValue }) => {
  try {
    const response = await api.put('/auth/profile', profileData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error al actualizar el perfil');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    recoveryMessage: null,
    token: localStorage.getItem('token') || null,
    globalMessage: null,
    isAuthenticated: !!localStorage.getItem('token'),
    isAdmin: localStorage.getItem('rol') === 'administrador',
    isSocio: localStorage.getItem('rol') === 'socio',
    usuario: {
      nombre: localStorage.getItem('nombre') || '',
      apellido: localStorage.getItem('apellido') || '',
      rol: localStorage.getItem('rol') || '',
      email_personal: localStorage.getItem('email_personal') || '',
      contacto_emergencia: localStorage.getItem('contacto_emergencia') || '',
      telefono_emergencia: localStorage.getItem('telefono_emergencia') || '',
    },
    cambioClave: false,
  },
  reducers: {
    login: (state, action) => {
      const { token, rol, nombre, apellido, cambio_clave, completar_emergencia, email_personal, contacto_emergencia, telefono_emergencia } = action.payload;
      state.usuario = { rol, nombre, apellido };
      state.token = token;
      state.isAuthenticated = !!token;
      state.isAdmin = rol === 'administrador';
      state.isSocio = rol === 'socio';
      state.cambioClave = cambio_clave || false;
      state.completarEmergencia = completar_emergencia || false;

      localStorage.setItem('token', token);
      localStorage.setItem('nombre', nombre);
      localStorage.setItem('apellido', apellido);
      localStorage.setItem('rol', rol);
      localStorage.setItem('cambio_clave', cambio_clave);
      localStorage.setItem('completar_emergencia', completar_emergencia);
      localStorage.setItem('email_personal', email_personal || '');
      localStorage.setItem('contacto_emergencia', contacto_emergencia || '');
      localStorage.setItem('telefono_emergencia', telefono_emergencia || '');
    },
    logout: (state) => {
      state.usuario = { nombre: '', apellido: '', rol: '', email_personal: '', contacto_emergencia: '', telefono_emergencia: '' };
      state.token = null;
      state.isAuthenticated = false;
      state.isAdmin = false;
      state.isSocio = false;
      state.cambioClave = false;
      localStorage.clear();
    },
    setGlobalMessage(state, action) {
      state.globalMessage = action.payload;
    },
    initializeAuth: (state) => {
      state.token = localStorage.getItem('token') || null;
      state.isAuthenticated = !!state.token;
      state.usuario = {
        nombre: localStorage.getItem('nombre') || '',
        apellido: localStorage.getItem('apellido') || '',
        rol: localStorage.getItem('rol') || '',
        email_personal: localStorage.getItem('email_personal') || '',
        contacto_emergencia: localStorage.getItem('contacto_emergencia') || '',
        telefono_emergencia: localStorage.getItem('telefono_emergencia') || '',
      };
      state.isAdmin = state.usuario.rol === 'administrador';
      state.isSocio = state.usuario.rol === 'socio';
      state.cambioClave = localStorage.getItem('cambio_clave') === 'true';
      state.completarEmergencia = localStorage.getItem('completar_emergencia') === 'true';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAsync.fulfilled, (state) => {
        state.usuario = { nombre: '', apellido: '', rol: '', email_personal: '', contacto_emergencia: '', telefono_emergencia: '' };
        state.token = null;
        state.isAuthenticated = false;
        state.isAdmin = false;
        state.isSocio = false;
        state.cambioClave = false;
        localStorage.clear();
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.logoutError = action.payload;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.usuario = action.payload;
        state.error = null;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.usuario = { ...state.usuario, ...action.meta.arg };
        state.error = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(requestPasswordRecovery.fulfilled, (state, action) => {
        state.recoveryMessage = action.payload.message;
      })
      .addCase(requestPasswordRecovery.rejected, (state, action) => {
        console.error('Error al solicitar recuperación:', action.payload); // Log para depuración
        state.recoveryMessage = action.payload.error || 'Error al solicitar recuperación de contraseña';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.globalMessage = 'Contraseña restablecida con éxito';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.globalMessage = action.payload;
      });
  },
});

export const { login, logout, initializeAuth, setGlobalMessage } = authSlice.actions;
export default authSlice.reducer;