import React, { useState, useEffect } from "react";
import { comunas } from "../../utils/comunas";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../redux/cargaMasivaSlice";
import FileUpload from "../../components/FileUpload";
import Notification from "../../components/Notification";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";

const CargaMasivaUsuariosPage = () => {
  const dispatch = useDispatch();
  const { loading, success, error, resultados } = useSelector(
    (state) => state.cargaMasiva
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedComuna, setSelectedComuna] = useState(null);

  useEffect(() => {
    if (success || error) {
      setShowModal(true);
    }
  }, [success, error]);

  const handleUpload = () => {
    if (!selectedFile) {
      alert("Por favor, selecciona un archivo Excel.");
      return;
    }

    const formData = new FormData();
    formData.append("archivoExcel", selectedFile);

    console.log("Archivo seleccionado para enviar:", selectedFile.name);
    console.log([...formData]); // Para verificar si el archivo está en el FormData

    dispatch(uploadFile(selectedFile));
  };


  return (
    <Container className="mt-4">
      <h2 className="text-center">Carga Masiva de Usuarios</h2>
      <p className="text-muted text-center">
        Selecciona un archivo Excel con los usuarios a registrar. Asegúrate de
        que el formato sea correcto.
      </p>

      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <Notification message={error} type="danger" />
          <Notification message={success} type="success" />
          <FileUpload onFileSelect={setSelectedFile} />

          <Form.Group className="mt-3">
            <Form.Label>Revisa los id's de las comunas aca! :</Form.Label>
            <Form.Control
              as="select"
              value={selectedComuna ? selectedComuna.id : ""}
              onChange={(e) => {
                const comunaSeleccionada = comunas.find(c => c.id === parseInt(e.target.value));
                setSelectedComuna(comunaSeleccionada || null);
              }}
            >
              <option value="">Seleccione una Comuna</option>
              {comunas.map((comuna) => (
                <option key={comuna.id} value={comuna.id}>
                  {comuna.nombre} (ID: {comuna.id})
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button
            variant="primary"
            className="mt-3"
            onClick={handleUpload}
            disabled={loading}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Subir Archivo"}
          </Button>
        </Col>
      </Row>

      {/* Modal con detalles del proceso */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Estado de la Carga</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <p>Subiendo archivo, por favor espera...</p>}
          {success && <p className="text-success">{success}</p>}
          {error && <p className="text-danger">{error}</p>}

          {resultados?.usuariosRegistrados?.length > 0 && (
            <>
              <h5>Usuarios Registrados</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Comuna</th>
                  </tr>
                </thead>
                <tbody>
                  {resultados.usuariosRegistrados.map((usuario, index) => {
                    const comuna = comunas.find(c => c.id === usuario.comuna_id);
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{usuario.nombre}</td>
                        <td>{usuario.email}</td>
                        <td>{comuna ? comuna.nombre : "Desconocida"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <h5>Errores en la Carga</h5>
              <ul className="text-danger">
                {resultados.errores.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Instrucciones para el usuario */}
      <Row className="mt-5">
        <Col>
          <h4>Formato del Archivo Excel</h4>
          <p>
            El archivo Excel debe tener los siguientes encabezados en la primera fila:
          </p>
          <Table striped bordered>
            <thead>
              <tr>
                <th>nombre</th>
                <th>primer_apellido</th>
                <th>segundo_apellido</th>
                <th>rut</th>
                <th>dv</th>
                <th>email</th>
                <th>telefono</th>
                <th>fecha_nacimiento</th>
                <th>empresa</th>
                <th>comuna_id</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Juan</td>
                <td>Pérez</td>
                <td>González</td>
                <td>12345678</td>
                <td>9</td>
                <td>juan@test.com</td>
                <td>+56912345678</td>
                <td>1990-05-10</td>
                <td>Claro</td>
                <td>121 (Puente Alto)</td>
              </tr>
            </tbody>
          </Table>
          <p>
            <strong>Nota:</strong> La columna <code>comuna_id</code> debe coincidir con el ID
            de la comuna registrada en la base de datos. Puedes ver los valores
            disponibles en la lista desplegable arriba.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CargaMasivaUsuariosPage;
