import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Alert, Row, Col, Card } from "react-bootstrap";
import api from "../../utils/axiosConfig";
import { fetchRegiones, fetchComunasByRegion } from "../../redux/regionComunaSlice";



const RegisterUser = () => {
  const dispatch = useDispatch();
  const { regiones = [], comunas = [] } = useSelector((state) => state.regionComuna);

  useEffect(() => {

    dispatch(fetchRegiones());
  }, [dispatch]);




  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    rut: "",
    dv: "",
    telefono: "",
    contacto_emergencia: "",
    telefono_emergencia: "",
    direccion: "",
    comuna_id: "",
    region_id: "",
    rol: "socio",
    fecha_nacimiento: "",
    fecha_ingreso: "",
    empresa: "claro",
  });

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);




  const handleRegionChange = (e) => {
    const regionId = e.target.value || ""; // 🔥 Evita que sea null
    setFormData({
      ...formData,
      region_id: regionId,
      comuna_id: "", // 🔥 Resetear comuna para evitar valores incorrectos
    });
  
    if (regionId) {
      dispatch(fetchComunasByRegion(regionId));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password" || name === "confirmPassword") {
      setPasswordMatch(
        name === "confirmPassword"
          ? value === formData.password
          : value === formData.confirmPassword
      );
    }
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = [];
    const fechaNacimiento = new Date(formData.fecha_nacimiento);
    const fechaIngreso = new Date(formData.fecha_ingreso);
    const today = new Date();
    
    // Validación de edad mínima
    let age = today.getFullYear() - fechaNacimiento.getFullYear();
    const month = today.getMonth() - fechaNacimiento.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < fechaNacimiento.getDate())) {
      age--;
    }
  
    if (isNaN(fechaNacimiento.getTime())) {
      errors.push("La fecha de nacimiento debe ser válida.");
    } else if (fechaNacimiento > today) {
      errors.push("La fecha de nacimiento no puede ser futura.");
    } else if (age < 18) {
      errors.push("Debe tener al menos 18 años para registrarse.");
    }
  
    if (isNaN(fechaIngreso.getTime())) {
      errors.push("La fecha de ingreso debe ser válida.");
    } else if (fechaIngreso > today) {
      errors.push("La fecha de ingreso no puede ser futura.");
    }
  
    // Validaciones de email
    if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)) {
      errors.push("El email no es válido.");
    }
    if (
      formData.email_personal &&
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email_personal)
    ) {
      errors.push("El correo personal debe ser un email válido.");
    }
  
    // Validación de contraseña
    if (
      formData.password.length < 8 ||
      !/[a-z]/.test(formData.password) ||
      !/[A-Z]/.test(formData.password) ||
      !/[0-9]/.test(formData.password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)
    ) {
      errors.push(
        "La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un carácter especial."
      );
    }
  
    // Validación de campos de texto (nombre y apellidos)
    if (!/^[a-zA-Z]+$/.test(formData.nombre)) {
      errors.push("El nombre solo puede contener letras.");
    }
    if (!/^[a-zA-Z]+$/.test(formData.primer_apellido)) {
      errors.push("El primer apellido solo puede contener letras.");
    }
    if (!/^[a-zA-Z]+$/.test(formData.segundo_apellido)) {
      errors.push("El segundo apellido solo puede contener letras.");
    }
  
    // Validación de RUT y dígito verificador
    if (!/^[0-9]{1,8}$/.test(formData.rut)) {
      errors.push("El RUT debe tener entre 1 y 8 dígitos.");
    }
    if (!/^[0-9kK]$/.test(formData.dv)) {
      errors.push("El dígito verificador debe ser un número del 0 al 9 o la letra k/K.");
    }
  
    // Validación de teléfono
    if (!/^\+56\d{8,9}$/.test(formData.telefono)) {
      errors.push("El teléfono debe ser un número válido para Chile (formato +56XXXXXXXX).");
    }
  
    // Validación de rol
    if (!["administrador", "socio"].includes(formData.rol)) {
      errors.push('Error de Rol, no autorizado.');
    }
  
    // Validación de empresa
    if (!["claro", "vtr"].includes(formData.empresa)) {
      errors.push('La empresa debe ser "claro" o "vtr".');
    }
  
    // Validación de comuna
    if (!/^\d+$/.test(formData.comuna_id)) {
      errors.push("La comuna es obligatoria y debe ser un número entero válido.");
    }
  
    // Validación de contacto y teléfono de emergencia (opcionales)
    if (formData.contacto_emergencia && typeof formData.contacto_emergencia !== "string") {
      errors.push("El contacto de emergencia debe ser un texto válido.");
    }
    if (formData.telefono_emergencia && !/^\+56\d{8,9}$/.test(formData.telefono_emergencia)) {
      errors.push("El teléfono de emergencia debe ser un número válido para Chile (formato +56XXXXXXXX).");
    }
  
    // Validación de dirección
    if (formData.direccion && formData.direccion.length > 255) {
      errors.push("La dirección no puede exceder los 255 caracteres.");
    }
  
    return errors;
  };
  



  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setErrors([]);

    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (!passwordMatch) {
      setErrors([{ msg: "Las contraseñas no coinciden" }]);
      return;
    }

    if (!formData.region_id || !formData.comuna_id) {
      setErrors([{ msg: "Debe seleccionar una región y una comuna." }]);
      return;
    }

    // Asignar valores predeterminados si no se proporcionan
    const updatedFormData = {
      ...formData,
      contacto_emergencia: formData.contacto_emergencia || "No disponible",
      telefono_emergencia: formData.telefono_emergencia || "No disponible",
    };

    try {
      const response = await api.post("/auth/register", updatedFormData);
      // Debug completo
      console.log("Datos enviados al backend:", JSON.stringify(updatedFormData, null, 2));
      console.log("Respuesta del backend:", response);
      if (response.status === 201) {
        setMessage("Usuario registrado con éxito");
        setFormData({
          email: "",
          password: "",
          confirmPassword: "",
          nombre: "",
          primer_apellido: "",
          segundo_apellido: "",
          rut: "",
          dv: "",
          telefono: "",
          contacto_emergencia: "",
          telefono_emergencia: "",
          direccion: "",
          comuna_id: "",
          region_id: "",
          rol: "socio",
          fecha_nacimiento: "",
          fecha_ingreso: "",
          empresa: "claro",
        });
        setPasswordMatch(true);
      }
    } catch (error) {
      console.error("Error inesperado al registrar usuario:", error);
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
      else if (error.request) {
        console.log("Error Request:", error.request);
        setErrors([{ msg: "No se pudo conectar con el servidor" }]);
      } else {
        console.error("Error inesperado al registrar usuario:", error);
        setErrors([{ msg: `Error inesperado: ${error.message}` }]);
      }
    }
  };
  return (
    <div>
      <h2>Registrar Usuario</h2>

      {/* Mensaje de éxito */}
      {message && <Alert variant="success">{message}</Alert>}

      {/* Mensajes de error */}
      {errors.length > 0 &&
        errors.map((err, index) => (
          <Alert key={index} variant="danger">
            {err.msg || "Error inesperado"}
          </Alert>
        ))}

      <Card>
        <Form onSubmit={handleSubmit}>
          <Row>
            {/* Columna izquierda */}
            <Col md={6}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Correo electrónico"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formRut" className="mb-3">
                <Form.Label>RUT</Form.Label>
                <Form.Control
                  type="text"
                  name="rut"
                  placeholder="12345678"
                  value={formData.rut}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formDv" className="mb-3">
                <Form.Label>Dígito Verificador</Form.Label>
                <Form.Control
                  type="text"
                  name="dv"
                  placeholder="9"
                  value={formData.dv}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formTelefono" className="mb-3">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  placeholder="+56912345678"
                  value={formData.telefono}
                  onChange={handleChange}
                  required
                />

              </Form.Group>


              <Form.Group controlId="formContactoEmergencia" className="mb-3">
                <Form.Label>Contacto de Emergencia</Form.Label>
                <Form.Control
                  type="text"
                  name="contacto_emergencia"
                  placeholder="Nombre del contacto de emergencia"
                  value={formData.contacto_emergencia}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formTelefonoEmergencia" className="mb-3">
                <Form.Label>Teléfono de Emergencia</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono_emergencia"
                  placeholder="+56987654321"
                  value={formData.telefono_emergencia}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formRegion" className="mb-3">
                <Form.Label>Región</Form.Label>
                <Form.Select
                  name="region_id"
                  value={formData.region_id || ""}
                  onChange={handleRegionChange}
                  required
                >
                  <option value="">Seleccione una región</option>
                  {Array.isArray(regiones) &&
                    regiones.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.nombre}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="formComuna" className="mb-3">
                <Form.Label>Comuna</Form.Label>
                <Form.Select
                  name="comuna_id"
                  value={formData.comuna_id || ""}
                  onChange={handleChange}
                  required
                  disabled={!formData.region_id || comunas.length === 0}
                >
                  <option value="">Seleccione una comuna</option>
                  {Array.isArray(comunas) &&
                    comunas.map((comuna) => (
                      <option key={comuna.id} value={comuna.id}>
                        {comuna.nombre}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="formDireccion" className="mb-3">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="direccion"
                  placeholder="Ingrese su dirección"
                  value={formData.direccion}
                  onChange={handleChange}  // Utiliza el manejador de cambios
                  required
                />
              </Form.Group>

            </Col>




            {/* Columna derecha */}
            <Col md={6}>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formConfirmPassword" className="mb-3">
                <Form.Label>Confirmar Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirme la contraseña"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  isInvalid={!passwordMatch}
                  required
                />
                {!passwordMatch && (
                  <Form.Text className="text-danger">
                    Las contraseñas no coinciden
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formNombre" className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Juan"
                  value={formData.nombre}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPrimerApellido" className="mb-3">
                <Form.Label>Primer Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="primer_apellido"
                  placeholder="Perez"
                  value={formData.primer_apellido}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formSegundoApellido" className="mb-3">
                <Form.Label>Segundo Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="segundo_apellido"
                  placeholder="Gomez"
                  value={formData.segundo_apellido}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formFechaNacimiento" className="mb-3">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_nacimiento"
                  value={formData.fecha_nacimiento}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formFechaIngreso" className="mb-3">
                <Form.Label>Fecha de Ingreso</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_ingreso"
                  value={formData.fecha_ingreso}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmpresa" className="mb-3">
                <Form.Label>Empresa</Form.Label>
                <Form.Select
                  name="empresa"
                  value={formData.empresa}
                  onChange={handleChange}
                  required
                >
                  <option value="claro">Claro</option>
                  <option value="vtr">VTR</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          {/* Botones */}
          <Row className="mt-3">
            <Col>
              <Button variant="primary" type="submit" disabled={!passwordMatch}>
                Registrar
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={() =>
                  setFormData({
                    email: "",
                    password: "",
                    confirmPassword: "",
                    nombre: "",
                    primer_apellido: "",
                    segundo_apellido: "",
                    rut: "",
                    dv: "",
                    telefono: "",
                    contacto_emergencia: "",
                    telefono_emergencia: "",
                    direccion: "",
                    comuna_id: "",
                    region_id: "",
                    rol: "socio",
                    fecha_nacimiento: "",
                    fecha_ingreso: "",
                    empresa: "claro",
                  })
                }
                className="ms-2"
              >
                Limpiar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default RegisterUser;
