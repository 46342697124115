import React from "react";

const FileUpload = ({ onFileSelect }) => {
  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      onFileSelect(event.target.files[0]);
    }
  };

  return (
    <div className="file-upload">
      <input type="file" accept=".xlsx" onChange={handleFileChange} />
    </div>
  );
};

export default FileUpload;
