export const comunas = [
      { id: 1, nombre: "Arica" },
      { id: 2, nombre: "Camarones" },
      { id: 3, nombre: "Putre" },
      { id: 4, nombre: "General Lagos" },
      { id: 5, nombre: "Iquique" },
      { id: 6, nombre: "Alto Hospicio" },
      { id: 7, nombre: "Pozo Almonte" },
      { id: 8, nombre: "Camiña" },
      { id: 9, nombre: "Colchane" },
      { id: 10, nombre: "Huara" },
      { id: 11, nombre: "Pica" },
      { id: 12, nombre: "Antofagasta" },
      { id: 13, nombre: "Mejillones" },
      { id: 14, nombre: "Sierra Gorda" },
      { id: 15, nombre: "Taltal" },
      { id: 16, nombre: "Calama" },
      { id: 17, nombre: "Ollagüe" },
      { id: 18, nombre: "San Pedro de Atacama" },
      { id: 19, nombre: "Tocopilla" },
      { id: 20, nombre: "María Elena" },
      { id: 21, nombre: "Copiapó" },
      { id: 22, nombre: "Caldera" },
      { id: 23, nombre: "Tierra Amarilla" },
      { id: 24, nombre: "Chañaral" },
      { id: 25, nombre: "Diego de Almagro" },
      { id: 26, nombre: "Vallenar" },
      { id: 27, nombre: "Freirina" },
      { id: 28, nombre: "Huasco" },
      { id: 29, nombre: "Alto del Carmen" },
      { id: 30, nombre: "La Serena" },
      { id: 31, nombre: "Coquimbo" },
      { id: 32, nombre: "Andacollo" },
      { id: 33, nombre: "La Higuera" },
      { id: 34, nombre: "Paiguano" },
      { id: 35, nombre: "Vicuña" },
      { id: 36, nombre: "Illapel" },
      { id: 37, nombre: "Canela" },
      { id: 38, nombre: "Los Vilos" },
      { id: 39, nombre: "Salamanca" },
      { id: 40, nombre: "Ovalle" },
      { id: 41, nombre: "Combarbalá" },
      { id: 42, nombre: "Monte Patria" },
      { id: 43, nombre: "Punitaqui" },
      { id: 44, nombre: "Río Hurtado" },
      { id: 45, nombre: "Valparaíso" },
      { id: 46, nombre: "Viña del Mar" },
      { id: 47, nombre: "Concón" },
      { id: 48, nombre: "Quilpué" },
      { id: 49, nombre: "Villa Alemana" },
      { id: 50, nombre: "Casablanca" },
      { id: 51, nombre: "Juan Fernández" },
      { id: 52, nombre: "San Antonio" },
      { id: 53, nombre: "Cartagena" },
      { id: 54, nombre: "El Tabo" },
      { id: 55, nombre: "El Quisco" },
      { id: 56, nombre: "Algarrobo" },
      { id: 57, nombre: "Santo Domingo" },
      { id: 58, nombre: "Quillota" },
      { id: 59, nombre: "La Calera" },
      { id: 60, nombre: "Nogales" },
      { id: 61, nombre: "Hijuelas" },
      { id: 62, nombre: "La Cruz" },
      { id: 63, nombre: "Limache" },
      { id: 64, nombre: "Olmué" },
      { id: 65, nombre: "Rancagua" },
      { id: 66, nombre: "Machalí" },
      { id: 67, nombre: "Graneros" },
      { id: 68, nombre: "Mostazal" },
      { id: 69, nombre: "Codegua" },
      { id: 70, nombre: "San Fernando" },
      { id: 71, nombre: "Santa Cruz" },
      { id: 72, nombre: "Chimbarongo" },
      { id: 73, nombre: "Nancagua" },
      { id: 74, nombre: "Pichilemu" },
      { id: 75, nombre: "Marchigüe" },
      { id: 76, nombre: "Litueche" },
      { id: 77, nombre: "Talca" },
      { id: 78, nombre: "San Clemente" },
      { id: 79, nombre: "Pelarco" },
      { id: 80, nombre: "Maule" },
      { id: 81, nombre: "Curepto" },
      { id: 82, nombre: "Linares" },
      { id: 83, nombre: "San Javier" },
      { id: 84, nombre: "Parral" },
      { id: 85, nombre: "Chanco" },
      { id: 86, nombre: "Pelluhue" },
      { id: 87, nombre: "Chillán" },
      { id: 88, nombre: "Bulnes" },
      { id: 89, nombre: "Quirihue" },
      { id: 90, nombre: "Coelemu" },
      { id: 91, nombre: "Yungay" },
      { id: 92, nombre: "Concepción" },
      { id: 93, nombre: "Talcahuano" },
      { id: 94, nombre: "Hualpén" },
      { id: 95, nombre: "Coronel" },
      { id: 96, nombre: "Lota" },
      { id: 97, nombre: "Tomé" },
      { id: 98, nombre: "Penco" },
      { id: 99, nombre: "Chiguayante" },
      { id: 100, nombre: "Temuco" },
      { id: 101, nombre: "Padre Las Casas" },
      { id: 102, nombre: "Villarrica" },
      { id: 103, nombre: "Pucón" },
      { id: 104, nombre: "Angol" },
      { id: 105, nombre: "Valdivia" },
      { id: 106, nombre: "Panguipulli" },
      { id: 107, nombre: "Lanco" },
      { id: 108, nombre: "Puerto Montt" },
      { id: 109, nombre: "Puerto Varas" },
      { id: 110, nombre: "Castro" },
      { id: 111, nombre: "Ancud" },
      { id: 112, nombre: "Coyhaique" },
      { id: 113, nombre: "Aysén" },
      { id: 114, nombre: "Punta Arenas" },
      { id: 115, nombre: "Puerto Natales" },
      { id: 116, nombre: "Porvenir" },
      { id: 117, nombre: "Santiago" },
      { id: 118, nombre: "Providencia" },
      { id: 119, nombre: "Las Condes" },
      { id: 120, nombre: "La Florida" },
      { id: 121, nombre: "Puente Alto" },
      { id: 122, nombre: "Maipú" },
      { id: 123, nombre: "Quilicura" },
      { id: 124, nombre: "San Bernardo" },
      { id: 125, nombre: "Estación Central" },
      { id: 126, nombre: "Renca" },
      { id: 127, nombre: "Ñuñoa" },
      { id: 128, nombre: "Huechuraba" },
      { id: 129, nombre: "Pudahuel" },
      { id: 130, nombre: "La Pintana" },
      { id: 131, nombre: "El Bosque" },
      { id: 132, nombre: "San Miguel" },
      { id: 133, nombre: "Pedro Aguirre Cerda" },
      { id: 134, nombre: "San Joaquín" },
      { id: 135, nombre: "Vicuña Mackenna" },
    ];